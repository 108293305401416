




















































































































































































































































































































































import { defineComponent, ref, watch, PropType } from '@vue/composition-api';
import moment from 'moment';
import {
  SQBody,
  SQBodyCell,
  SQEmpty,
  SQNoResults
} from './s-q-table-components';
import {
  useSQTableConfig,
  SQTableRow,
  SQTableColumn,
  SQTableFilter,
  SQTableAction,
  SQTablePagination,
  SearchEngine,
  SQTableEmptySate,
  SQTableNoResults
} from './s-q-table.hook';

export default defineComponent({
  name: 'SQTable',
  components: { SQBody, SQBodyCell, SQEmpty, SQNoResults },
  props: {
    rows: {
      type: Array as PropType<SQTableRow[]>,
      required: true
    },
    columns: {
      type: Array as PropType<SQTableColumn[]>,
      required: true
    },
    pagination: {
      type: Object as PropType<SQTablePagination>,
      default: () => null
    },
    filtersDefinitions: {
      type: Array as PropType<SQTableFilter[]>,
      default: () => []
    },
    actions: {
      type: Array as PropType<SQTableAction[]>,
      default: () => []
    },
    searchEngine: {
      type: Function as PropType<SearchEngine>,
      default: () => null
    },
    rowsPerPage: {
      type: Array as PropType<number[]>,
      default: () => [10, 15, 25, 50]
    },
    loading: {
      type: Boolean,
      required: true
    },
    empty: {
      type: Object as PropType<SQTableEmptySate>,
      default: () => null
    },
    noResults: {
      type: Object as PropType<SQTableNoResults>,
      default: () => null
    },
    total: {
      type: Number,
      required: false
    },
    roundedHeader: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      filters,
      initialPagination,
      MENU_ICON,
      reloadFilters,
      isModelEmpty,
      getValidActions,
      formatDate,
      notEmptyDate,
      updatePagination,
      onRequest
    } = useSQTableConfig(
      props.filtersDefinitions,
      props.searchEngine,
      props.pagination
    );

    const tableRef = ref();

    watch(
      () => props.pagination,
      (newPagination) => {
        updatePagination(newPagination);
      },
      { deep: true }
    );

    watch(() => props.filtersDefinitions, reloadFilters, { deep: true });

    return {
      tableRef,
      filters,
      initialPagination,
      moment,
      MENU_ICON,
      isModelEmpty,
      getValidActions,
      formatDate,
      notEmptyDate,
      onRequest
    };
  }
});
