import { computed, ref, Ref, ComputedRef } from '@vue/composition-api';
import {
  SQTableColumn,
  SQTableRow,
  SQTablePagination
} from '../base/tables/s-q-table.hook';
import {
  NotaryProductService,
  BankingService
} from '@ligo/dashboard/customer/store';
import {
  NotaryProduct,
  NotaryProductTypeSlug,
  UpsellPackageNotaryProductSlug,
  BankingRequest,
  DeprecatedServices,
  BankingRequestStatuses
} from '@ligo/dashboard/store';
import {
  simpleNotification,
  initWebSocket,
  getBaseSocketURL,
  Dictionary
} from '@ligo/shared/utils';
import { useRouter } from '../../composables';
import moment from 'moment';
import { BankingPageInformation } from '../../components/utils/useCustomerPageInformation.hooks';

const NPTSlugs = {
  ...NotaryProductTypeSlug,
  ...UpsellPackageNotaryProductSlug,
  ...DeprecatedServices
};
const ValidProductsForAccountRequest = [
  NPTSlugs.DUTCH_BV,
  NPTSlugs.ENGLISH_BV,
  NPTSlugs.ENGLISH_HOLDING,
  NPTSlugs.HOLDING,
  NPTSlugs.UPSELL_HOLDING,
  NPTSlugs.UPSELL_INCORPORATE_HOLDING,
  NPTSlugs.OMZETTING,
  NPTSlugs.STITCHING,
  NPTSlugs.ANBI_STITCHING
];

const ValidStagesForAccountRequest = [
  'extra_questions',
  'submitted_mix',
  'legal_support_checking_documents',
  'checking_documents',
  'uploading_fca',
  'scheduling_meeting',
  'executing_deed',
  'extracting_kvk',
  'finalized',
  'canceled',
  'on_hold'
];

export function useBanking() {
  const {
    t,
    isReady: isPageInformationReady,
    load: loadPageInformation
  } = BankingPageInformation;

  const loading = ref(true);
  const requests: Ref<BankingRequest[]> = ref([]);
  const openedWS: Dictionary<boolean> = {};

  const columns: Ref<SQTableColumn[]> = computed(() => {
    return [
      {
        name: 'for',
        label: t('table.columns')[0],
        align: 'left',
        field: 'for'
      },
      {
        name: 'status',
        label: t('table.columns')[1],
        align: 'left',
        field: 'status'
      },
      {
        name: 'type',
        label: t('table.columns')[2],
        align: 'left',
        field: 'type'
      },
      {
        name: 'request_on',
        label: t('table.columns')[3],
        align: 'left',
        field: 'requestOn'
      }
    ];
  });

  const rows: ComputedRef<SQTableRow[]> = computed(() => {
    return requests.value.map((request) => ({
      for: request.notary_product.name,
      status: {
        value: t(`table.statuses.${request.aasm_state}`),
        slug: request.aasm_state,
        textColor: statusColor(request.aasm_state).textColor,
        color: statusColor(request.aasm_state).color,
        radius: 10
      },
      type: {
        value: request.notary_product.notary_product_type.name,
        textColor: 'subdued',
        color: 'grey-light',
        radius: 10
      },
      requestOn: moment(request.created_at).format('DD MMM YYYY'),
      onboardingUrl: request.onboarding_url
    }));
  });

  const pagination: Ref<SQTablePagination> = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 5,
    rowsNumber: 10
  });

  const emptyState = computed(() => ({
    title: t('table.empty.title'),
    subtitle: t('table.empty.subtitle'),
    btnLabel: t('table.empty.btn_label'),
    icon: `img:${t('table.empty.icon')}`,
    iconSize: t('table.empty.icon_size')
  }));

  function statusColor(status: BankingRequestStatuses) {
    switch (status) {
      case BankingRequestStatuses.COMPLETED ||
        BankingRequestStatuses.REVIEW_APPROVED:
        return { textColor: 'green', color: 'green-light' };
      case BankingRequestStatuses.REVIEW_REJECTED ||
        BankingRequestStatuses.REVIEW_CANCELLED:
        return { textColor: 'red', color: 'red-light' };
      default:
        return { textColor: 'yellow', color: 'yellow-light' };
    }
  }

  function setupBankingRequestWebSocket() {
    requests.value.forEach((request) => {
      const requestId = request.uuid;
      const status = request.aasm_state;
      const { url, channelIdentifier } = getBaseSocketURL(
        requestId,
        'CustomerDashboard::BankingRequestsChannel'
      );
      const isFinalized = [
        BankingRequestStatuses.COMPLETED,
        BankingRequestStatuses.REVIEW_CANCELLED,
        BankingRequestStatuses.REVIEW_REJECTED
      ].includes(status);
      const hasWS = openedWS[requestId];
      if (!isFinalized && !hasWS) {
        openedWS[requestId] = true;
        initWebSocket(
          undefined,
          undefined,
          url,
          channelIdentifier,
          (_state, _response) => {
            getBankingRequests();
          },
          false
        );
      }
    });
  }

  function getBankingRequests() {
    loading.value = true;
    BankingService.requests({
      page: pagination.value.page,
      per_page: pagination.value.rowsPerPage
    })
      .then((response) => {
        requests.value = response.data;
        setupBankingRequestWebSocket();
        pagination.value.rowsNumber = parseInt(response.headers['x-total']);
      })
      .catch((e) => {
        console.error('There was an error fetching banking requests', e);
      })
      .finally(() => {
        loading.value = false;
      });

    return { loading, requests };
  }

  function search(filter, page, rowsPerPage, sortBy, descending) {
    pagination.value.page = page;
    pagination.value.rowsPerPage = rowsPerPage;
    pagination.value.sortBy = sortBy;
    pagination.value.descending = descending;

    getBankingRequests();
  }

  getBankingRequests();
  loadPageInformation();

  return {
    columns,
    rows,
    pagination,
    loading,
    emptyState,
    t,
    isPageInformationReady,
    search,
    getBankingRequests
  };
}

export function useBankingAccount() {
  const {
    t,
    isReady: isPageInformationReady,
    load: loadPageInformation
  } = BankingPageInformation;

  const router = useRouter();
  const loading = ref(true);
  const notaryProducts: Ref<NotaryProduct[]> = ref([]);
  const selectedNotaryProducts: Ref<NotaryProduct[]> = ref([]);
  const termsAndConditions = ref(false);

  const activeStep = ref(1);
  const stepsNumber = 3;
  const step = {
    checked: {
      color: 'np-primary-1100',
      type: 'icon',
      icon: 'fas fa-check'
    },
    current: {
      color: 'default',
      type: 'text'
    },
    disabled: {
      color: 'disable',
      type: 'text'
    }
  };
  const stepsActions = [
    {
      nextEnabled: computed(() => selectedNotaryProducts.value.length > 0),
      showNext: true,
      showBack: false
    },
    {
      nextEnabled: computed(() => termsAndConditions.value),
      showNext: true,
      showBack: true
    },
    {
      showNext: false,
      showBack: false,
      callback: sendRequest
    }
  ];

  const query = ref({
    query: undefined,
    stages: ValidStagesForAccountRequest,
    page: '1',
    per_page: '30',
    missing_banking_request: true,
    notary_product_types: ValidProductsForAccountRequest
  });

  function load(callback?: () => void) {
    loading.value = true;
    NotaryProductService.list(query.value)
      .then((response) => {
        notaryProducts.value = response.data;
      })
      .catch((e) => {
        console.error('There was an error fetching products', e);
      })
      .finally(() => {
        loading.value = false;
        if (callback) {
          callback();
        }
      });
  }

  function filter(name: string, update: () => void) {
    if (name.length === 1) {
      update();
    } else {
      query.value.query = name;
      load(update);
    }
  }

  function sendRequest() {
    const success = ref(false);
    Promise.all(
      selectedNotaryProducts.value.map((product) =>
        BankingService.account_request(product.uuid)
      )
    )
      .then(() => {
        simpleNotification({
          message: t('success_msg'),
          timeout: 5000
        });
        success.value = true;
      })
      .catch((e) => {
        console.error('There was an error sending banking requests', e);
        simpleNotification(undefined, t('error_msg'));
      })
      .finally(() => {
        setTimeout(() => {
          router.push({
            name: 'Banking',
            hash: '#banking-requests-table',
            query: { success: success.value.toString() }
          });
        }, 1000);
      });
  }

  loadPageInformation();

  return {
    loading,
    notaryProducts,
    selectedNotaryProducts,
    stepsNumber,
    activeStep,
    step,
    stepsActions,
    termsAndConditions,
    t,
    isPageInformationReady,
    filter
  };
}
