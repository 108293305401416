

















































































































































import {
  defineComponent,
  onBeforeMount,
  computed,
  ref
} from '@vue/composition-api';
import { useNotaryProduct } from '../../components/notary-product-detail.hook';
import {
  NotaryProductStage,
  NotaryProductTypeSlug,
  UpsellPackageNotaryProductSlug
} from '@ligo/dashboard/store';
import ReadUploadStep from '../../components/notary-product/read-and-upload/ReadUploadStep.handler.vue';
import DeedDocumentHandler from '../../components/notary-product/draft-deed/DeedDocument.handler.vue';
import StateStepper from '../../components/notary-product/StateStepper.vue';
import FinalizedProduct from '../../components/notary-product/final-step/FinalizedProduct.vue';
import CheckDocuments from '../../components/notary-product/check-documents/CheckDocuments.vue';
import ScheduleMeeting from '../../components/notary-product/schedule-meeting/ScheduleMeeting.vue';
import MenuList from '../../components/notary-product/check-documents/Menu/MenuList.vue';
import { useBuildPaymentDialog } from '../../components/payment-response.hook';
import {
  PaymentObject,
  sendGTMPaymentEvent
} from '@ligo/dashboard/customer/store';
import { NotaryProductEvent } from '@ligo/dashboard/store';
import BasePage from '../../components/base/BasePage.vue';
import ExtraQuestionsHandler from '../../components/notary-product/extra-questions/ExtraQuestions.handler.vue';
import AlertModal from '../../components/documents/base/AlertModal.vue';
import { APrimaryBtn, ABtn } from '../../components/base/app/buttons';
import { useStore } from '../../composables';
import { VidyardVideoModal } from '@ligo/shared/components';
import vidyardEmbed from '@vidyard/embed-code';

const ONBOARDING_VIDEO_UUID = 'NeEdHY7uapZ7CMdpMb4NYK';

export default defineComponent({
  name: 'NotaryProductDetails',
  components: {
    DeedDocumentHandler,
    StateStepper,
    FinalizedProduct,
    CheckDocuments,
    ScheduleMeeting,
    ReadUploadStep,
    BasePage,
    ExtraQuestionsHandler,
    AlertModal,
    APrimaryBtn,
    ABtn,
    VidyardVideoModal,
    MenuList
  },
  props: {
    uuid: {
      type: String,
      required: true
    },
    dialog: {
      type: String
    },
    pUuid: {
      type: String
    }
  },
  setup(props, { root }) {
    const introductionVideoProducts = [
      NotaryProductTypeSlug.ANBI_STITCHING,
      NotaryProductTypeSlug.DUTCH_BV,
      NotaryProductTypeSlug.HOLDING,
      NotaryProductTypeSlug.OMZETTING,
      NotaryProductTypeSlug.STITCHING
    ];

    const editProductTypes = [
      NotaryProductTypeSlug.DUTCH_BV,
      NotaryProductTypeSlug.HOLDING,
      NotaryProductTypeSlug.OMZETTING,
      NotaryProductTypeSlug.ENGLISH_BV,
      NotaryProductTypeSlug.ENGLISH_HOLDING,
      UpsellPackageNotaryProductSlug.UPSELL_HOLDING,
      UpsellPackageNotaryProductSlug.UPSELL_INCORPORATE_HOLDING
    ];

    const editProductStages = [
      NotaryProductStage.CHECKING_DOCUMENTS,
      NotaryProductStage.LS_CHECKING_DOCUMENTS,
      NotaryProductStage.SUBMITTED
    ];

    const store = useStore();
    const showExplanationModal = ref(false);
    const continueLoading = ref(true);

    const rename = ref<string>('');

    onBeforeMount(async () => {
      await store.dispatch('notaryProduct/select', props.uuid);
      await store.dispatch('layout/cleanDashboardTitle');
    });

    const uuid = computed(() => props.uuid);

    const {
      status,
      loading,
      load,
      stage,
      stageNumber,
      productType,
      notary,
      notaryId,
      onNotaryEvent,
      name,
      productData,
      vidyardTutorialId
    } = useNotaryProduct(root, uuid);

    const showIntroductionVideoBtn = computed(() =>
      introductionVideoProducts.includes(productType.value)
    );

    if (props.pUuid) sendGTMPaymentEvent(props.pUuid);

    const customTitle = computed(() => {
      if (productType.value == NotaryProductTypeSlug.TRANSFER)
        return 'payment_messages.notary_product.title_transfer_np';
      else if (productType.value == NotaryProductTypeSlug.STATUTEN)
        return 'payment_messages.notary_product.title_statuten_np';
      return null;
    });

    const { paymentDialog, showDialog } = useBuildPaymentDialog(
      root,
      props.dialog,
      name,
      PaymentObject.NOTARY_PRODUCT,
      true,
      customTitle
    );

    function onCloseDialog() {
      load();
    }

    function stopLoading() {
      continueLoading.value = false;
    }

    function renderPlayer() {
      vidyardEmbed.api.renderPlayer({
        uuid: vidyardTutorialId.value || ONBOARDING_VIDEO_UUID,
        container: document.getElementById('onboarding-video')
      });
    }

    function onRename(name: string) {
      rename.value = name;
    }

    function showMenuButton() {
      return (
        editProductStages.includes(stage.value) &&
        editProductTypes.includes(productType.value)
      );
    }

    function onEvent(event: NotaryProductEvent) {
      continueLoading.value = true;
      onNotaryEvent(event, false);
    }

    return {
      status,
      loading,
      stage,
      productType,
      NotaryProductStage,
      stageNumber,
      notary,
      notaryId,
      paymentDialog,
      showDialog,
      productData,
      name,
      continueLoading,
      showExplanationModal,
      showIntroductionVideoBtn,
      ONBOARDING_VIDEO_UUID,
      vidyardTutorialId,
      rename,
      onNotaryEvent,
      load,
      onCloseDialog,
      stopLoading,
      renderPlayer,
      onRename,
      showMenuButton,
      onEvent
    };
  }
});
