




























































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { useNavItems } from '../customer-layout.hooks';
import { useRouter } from '../../composables';
import NavItemTooltip from './NavItemTooltip.vue';
import { NavItem } from '../../models';

const INDEX_NO_HOVERED = -1;
const ICON_COLOR = 'default';
const BG_HOVER_COLOR = 'np-secondary-500';
const BG_ACTIVE_COLOR = 'default';

export default defineComponent({
  name: 'NavItemList',
  components: { NavItemTooltip },
  props: {
    useTooltips: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter();
    const itemHovered = ref(INDEX_NO_HOVERED);
    const childHovered = ref(INDEX_NO_HOVERED);
    const { navItems } = useNavItems();

    function activeChild(item: NavItem) {
      return item.children?.some(
        (child) => router.currentRoute.name === child.route
      );
    }

    function activeItem(item: NavItem) {
      return router.currentRoute.name === item.route;
    }

    function isActivePage(item: NavItem) {
      return activeItem(item) || activeChild(item);
    }

    function goRoute(name: string) {
      itemHovered.value = INDEX_NO_HOVERED;
      router.push({
        name
      });
    }

    function getItemIcon(item: NavItem) {
      return computed(() => {
        if (!item.closed?.value && hasChildren(item) && isActivePage(item)) {
          return {
            name: 'far fa-angle-up',
            class: 'semi-rotation-up'
          };
        } else {
          return {
            name: 'far fa-angle-down',
            class: 'semi-rotation-down'
          };
        }
      });
    }

    function hasChildren(item: NavItem) {
      return item.children && item.children.length;
    }

    function onMainItemClick(item: NavItem) {
      if (!isActivePage(item)) {
        goRoute(item.route);
        if (item.closed) item.closed.value = false;
      } else {
        if (activeChild(item)) goRoute(item.route);
        item.closed.value = !item.closed.value;
      }
    }

    function showChildren(item: NavItem) {
      return (
        !props.mini &&
        (!item.closed || !item.closed.value) &&
        isActivePage(item) &&
        hasChildren(item)
      );
    }

    return {
      navItems,
      itemHovered,
      childHovered,
      INDEX_NO_HOVERED,
      ICON_COLOR,
      BG_HOVER_COLOR,
      BG_ACTIVE_COLOR,
      showChildren,
      onMainItemClick,
      hasChildren,
      getItemIcon,
      isActivePage,
      activeItem,
      goRoute
    };
  }
});
